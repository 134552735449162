<template>
	<el-dialog title="" :visible.sync="createdVisible" width="578px" center :close-on-click-modal="false" @close="onResetForm('ruleForm')">
		<div slot="title" class="postion">
			<i v-if="!isShowDetail" class="iconfont icon-bianji1"></i>
			<span class="download">{{ isShowDetail ? '查看' : !isShowDetail && ruleForm.id ? '编辑' : '新增' }}机构</span>
		</div>
		<el-form :model="ruleForm" :rules="isShowDetail ? {} : rules" ref="ruleForm" label-width="100px" class="demo-ruleForm" label-position="top">
			<el-form-item label="机构类别" prop="discriminationCode">
				<el-select v-model="ruleForm.discriminationCode" placeholder="请选择" :disabled="isShowDetail">
					<el-option label="疾控中心" value="D"></el-option>
					<el-option label="卫生监督所" value="S"></el-option>
					<el-option label="其他" value="O"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="机构名称" prop="name" class="w50 mr16">
				<el-input v-model.trim="ruleForm.name" :disabled="isShowDetail" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item label="机构级别" prop="institutionTypeDictionaryItemId" class="w50 mr0">
				<el-select
					:disabled="isShowDetail"
					v-model="ruleForm.institutionTypeDictionaryItemId"
					placeholder="请选择"
					@change="changeSelect($event, 'institutionTypeDictionaryItemId')"
				>
					<el-option v-for="(item, index) in institutionTypeDictionaryItemList" :key="index" :label="item.title" :value="item.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="机构代码" prop="organizationCode" class="w50 mr16">
				<el-input v-model.trim="ruleForm.organizationCode" :disabled="isShowDetail" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item label="主管单位" prop="administrativeAuthorityDictionaryItemId" class="w50 mr0">
				<el-select
					:disabled="isShowDetail"
					v-model="ruleForm.administrativeAuthorityDictionaryItemId"
					placeholder="请选择"
					@change="changeSelect($event, 'administrativeAuthorityDictionaryItemId')"
				>
					<el-option v-for="(item, index) in authorityDictionaryItemList" :key="index" :label="item.title" :value="item.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="addressClass clearfix" label="地址">
				<el-form-item v-for="(item, index) in addressList" :key="index" :class="item.className">
					<el-input v-model.trim="item.default" disabled></el-input>
				</el-form-item>
			</el-form-item>
			<el-form-item label="详细地址" class="attractionAddress" prop="address">
				<el-input
					v-model.trim="ruleForm.address"
					disabled
					placeholder="请选择详细地址"
					:style="{ width: isShowDetail ? '100%' : 'calc(100% - 74px)' }"
				></el-input>
				<el-button type="text" @click="chooseAddress" v-if="!isShowDetail">选择地址</el-button>
			</el-form-item>
			<el-form-item label="乡镇/街道" v-if="ruleForm.address !== ''">
				<el-select disabled v-model="ruleForm.townCode" placeholder="请选择" @change="changeTown($event)">
					<el-option v-for="(item, index) in townList" :key="index" :label="item.title" :value="item.code"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="村" prop="villageCode" v-if="ruleForm.address !== ''">
				<el-select :disabled="isShowDetail" v-model="ruleForm.villageCode" placeholder="请选择" @change="changeSelect($event, 'villageCode')">
					<el-option v-for="(item, index) in villageList" :key="index" :label="item.title" :value="item.code"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="机构性质" prop="characteristic">
				<el-input v-model.trim="ruleForm.characteristic" :disabled="isShowDetail" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item class="w50 mr16" prop="principalName" label="负责人">
				<el-input v-model.trim="ruleForm.principalName" :disabled="isShowDetail" placeholder="姓名"></el-input>
			</el-form-item>
			<el-form-item class="w50 mr0" prop="phoneNumber">
				<el-input v-model.trim="ruleForm.phoneNumber" :disabled="isShowDetail" maxlength="11" placeholder="联系电话"></el-input>
			</el-form-item>
			<el-form-item label="机构介绍" prop="description">
				<el-input type="textarea" autosize :disabled="isShowDetail" v-model.trim="ruleForm.description"></el-input>
			</el-form-item>
			<el-form-item label="上传图片" prop="images">
				<Upload
					ref="uploadImage"
					v-model="ruleForm.images"
					modulePath="SERVICE_EVALUATION"
					@uploadImg="uploadImg"
					operator="zhangl"
					:maxCount="6"
					:disabled="isShowDetail"
				></Upload>
			</el-form-item>
		</el-form>
		<span slot="footer" class="dialog-footer">
			<el-button @click="onResetForm('ruleForm')" type="primary" v-if="isShowDetail">确 定</el-button>
			<el-button v-else type="primary" @click="onSave('ruleForm')" :loading="saveLoading">保 存</el-button>
			<el-button v-if="!isShowDetail" @click="onResetForm('ruleForm')">取 消</el-button>
		</span>
		<choose-address ref="chooseAddress" @changeAddress="changeAddress" />
	</el-dialog>
</template>

<script>
import { verifyPhone } from '@/utils/toolsValidate';
export default {
	components: {
		Upload: () => import('@/components/Upload/index.vue'),
		chooseAddress: () => import('../countyMedicalManager/chooseAddress.vue'),
	},
	data() {
		var checkPhone = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请输入联系方式'));
			} else if (!verifyPhone(value)) {
				callback(new Error('请输入正确的联系方式'));
			} else {
				callback();
			}
		};
		return {
			createdVisible: false,
			ruleForm: {
				name: '',
				organizationCode: '',
				administrativeAuthorityDictionaryItemId: '',
				institutionTypeDictionaryItemId: '',
				address: '',
				discriminationCode: '',
				characteristic: '',
				principalName: '',
				phoneNumber: '',
				description: '',
				townCode: '',
				villageCode: '',
				images: [],
			},
			authorityDictionaryItemList: [], //主管单位list
			institutionTypeDictionaryItemList: [], //机构类别list
			townList: [], //乡镇/街道list
			villageList: [], //村list
			addressList: [
				{
					label: '省',
					className: 'float-l addressClass-content',
					prop: 'economize',
					disabled: true,
					default: JSON.parse(sessionStorage.getItem('userInfo')).chinaRegionDtoList[0].title,
				},
				{
					label: '市',
					className: 'float-l addressClass-content',
					prop: 'market',
					disabled: true,
					default: JSON.parse(sessionStorage.getItem('userInfo')).chinaRegionDtoList[1].title,
				},
				{
					label: '区县',
					className: 'float-l addressClass-content mr0',
					prop: 'county',
					disabled: true,
					default: JSON.parse(sessionStorage.getItem('userInfo')).chinaRegionDtoList[2].title,
				},
			],
			rules: {
				discriminationCode: [{ required: true, message: '请选择', trigger: 'change' }],
				name: [{ required: true, message: '请输入', trigger: 'blur' }],
				organizationCode: [{ required: true, message: '请输入', trigger: 'blur' }],
				characteristic: [{ required: true, message: '请输入', trigger: 'blur' }],
				administrativeAuthorityDictionaryItemId: [{ required: true, message: '请选择', trigger: 'change' }],
				institutionTypeDictionaryItemId: [{ required: true, message: '请选择', trigger: 'change' }],
				villageCode: [{ required: true, message: '请选择', trigger: 'change' }],
				description: [{ required: true, message: '请输入', trigger: 'blur' }],
				address: [{ required: true, message: '请输入详细地址', trigger: 'blur' }],
				principalName: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
				phoneNumber: [{ required: true, validator: checkPhone, trigger: 'blur' }],
				images: [{ required: true, message: '请上传图片', trigger: 'change' }],
			},
			attachmentInfo: [],
			saveLoading: false,
			link: '',
		};
	},
	props: {
		isShowDetail: {
			type: Boolean,
			default: false,
		},
	},
	watch: {},
	mounted() {
		this.getSponsorList();
		this.getInstitTypeList();
		this.getTown();
	},
	methods: {
		init(parkInfo) {
			this.createdVisible = true;
			this.getAttachment();
			this.link = parkInfo.link;
			if (parkInfo.row) {
				this.$nextTick(() => {
					this.setRuleForm(parkInfo.row);
					this.$refs.ruleForm.clearValidate();
				});
				return;
			}
			//新增
			this.ruleForm = this.$options.data().ruleForm; //清空表单
			this.$nextTick(() => {
				this.$refs.ruleForm.clearValidate();
			});
		},
		// 修改/查看
		setRuleForm(data) {
			this.ruleForm = JSON.parse(JSON.stringify(data));
			this.ruleForm.administrativeAuthorityDictionaryItemId = data.administrativeAuthorityDictionaryItem?.id || '';
			this.ruleForm.levelDictionaryItemId = data.levelDictionaryItem?.id || null;
			this.ruleForm.ownershipDictionaryItemId = data.ownershipDictionaryItem?.id || null;
			this.ruleForm.institutionTypeDictionaryItemId = data.institutionTypeDictionaryItem?.id || '';
			this.ruleForm.townCode = data.regions[3].code;
			this.ruleForm.villageCode = data.regionCode;
			this.changeTown(this.ruleForm.townCode);
			this.ruleForm.images =
				(data.attachmentDtoMap &&
					data.attachmentDtoMap['PHOTO'].map((item) => {
						return {
							url: item.link,
							uid: item.id,
							title: item.title,
						};
					})) ||
				[];
		},
		// 获取主管单位list
		getSponsorList() {
			this.$http
				.get(this.api['DictionaryItems#index'].href, { params: { dictionaryCode: 'COMPETENT_ORGANIZATION', size: 9999, current: 1 } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.authorityDictionaryItemList = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
		getInstitTypeList() {
			this.$http
				.get(this.api['DictionaryItems#index'].href, { params: { dictionaryCode: 'INSTITUTIONAL_TYPE', size: 9999, current: 1 } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.institutionTypeDictionaryItemList = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
		// 获取乡镇/街道list
		getTown() {
			this.$http
				.get(this.api['ChinaRegions#index'].href, { params: { code: JSON.parse(sessionStorage.getItem('userInfo')).chinaRegionDtoList[2].code } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.townList = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
		changeTown(code) {
			this.villageList = [];
			if (!code || code == '') return;
			this.$http
				.get(this.api['ChinaRegions#index'].href, { params: { code } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.villageList = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
		changeSelect(val, type) {
			this.ruleForm[type] = val;
			this.$forceUpdate();
		},
		// 获取附件组
		getAttachment() {
			this.$http
				.get(this.api['AttachmentGroups#index'].href, { params: { subjectTypeCode: 'SERVICE_EVALUATION' } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.attachmentInfo = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
		// 选择地址
		chooseAddress() {
			let data = JSON.parse(sessionStorage.getItem('userInfo')) || {};
			if (data.id) {
				this.$refs.chooseAddress.center = [data.longitude || 0, data.latitude || 0];
			}
			this.$refs.chooseAddress.chooseAddressVisible = true;
		},
		// 获取到的地址
		changeAddress(data) {
			const { addressData } = data;
			let townCode = JSON.parse(sessionStorage.getItem('userInfo'))?.chinaRegionDtoList[2]?.code || 0;
			let townTitle = JSON.parse(sessionStorage.getItem('userInfo'))?.chinaRegionDtoList[2]?.title || '';
			if (addressData.adcode != townCode) {
				this.$message.error(`请选择${townTitle}的地址`);
				return;
			}
			this.ruleForm.address = addressData.district + addressData.township + addressData.street + addressData.streetNumber;
			this.ruleForm.longitude = data.lng;
			this.ruleForm.latitude = data.lat;
			this.getTownCode({ code: addressData.adcode, title: addressData.township });
		},
		// 查询乡镇/街道code
		getTownCode({ code, title }) {
			this.$http
				.get(this.api['ChinaRegions#index'].href, { params: { code, title } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.$set(this.ruleForm, 'townCode', res.data.collection[0].code);
						this.changeTown(res.data.collection[0].code);
						this.$forceUpdate();
					}
				})
				.catch((e) => {});
		},
		// 保存
		onSave(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					this.saveLoading = true;
					const { villageCode } = this.ruleForm;
					let params = {
						...this.ruleForm,
						regionCode: villageCode,
						attachments: this.ruleForm.images.map((item) => {
							return {
								link: item.url,
								subjectTypeCode: this.attachmentInfo.find((item) => item.code == 'PHOTO').subjectTypeCode,
								title: item.title,
								attachmentGroupId: this.attachmentInfo.find((item) => item.code == 'PHOTO').id,
								mimeType: item.title.split('.')[1],
							};
						}),
					};
					console.log(params, 'params');
					let promiseUrl = this.ruleForm.id ? this.$http.put(this.link, params) : this.$http.post(this.link, params);
					promiseUrl
						.then((res) => {
							if (res.data && res.data.success) {
								this.$message.success(this.ruleForm.id ? '修改成功' : '添加成功');
								this.$emit('getList');
								this.onResetForm('ruleForm');
								this.saveLoading = false;
							}
						})
						.catch((e) => {
							this.saveLoading = false;
						});
				}else {
					this.$nextTick(() => {
						let isError = document.getElementsByClassName('is-error');
						// var a = $(isError[0]).find("input") //this.$refs.cmd.$el.querySelector('input')
						// a.focus()  光标定位到所在input
						isError[0].scrollIntoView({
							// 滚动到指定节点
							// 值有start,center,end，nearest
							block: 'center',
							// 值有auto、instant,smooth，缓动动画
							behavior: 'smooth',
						});
					});
					return false;
				}
			});
		},
		onResetForm(formName) {
			this.createdVisible = false;
			this.ruleForm.images = [];
			this.$refs.uploadImage.images = [];
			this.$refs[formName].resetFields();
		},
		uploadImg(dataImg) {
			this.ruleForm.images = dataImg.map((item) => {
				return {
					url: item.url,
					uid: item.uid,
					title: item.title,
				};
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.postion {
	font-size: 18px;
	.icon-bianji1 {
		margin-right: 16px;
		color: #1db9b1;
	}
}
/deep/ .el-form {
	.el-form-item {
		.el-select,
		.el-input {
			width: 100%;
		}
	}
	.el-form-item__label {
		line-height: 14px;
		height: 14px;
		margin-bottom: 8px;
		padding: 0;
	}
	.addressClass {
		&-content {
			width: calc((100% - 32px) / 3);
			margin-right: 16px;
			margin-bottom: 0;
		}
	}
}
.attractionAddress {
	/deep/ .el-button {
		width: 56px;
		border-bottom: 1px solid #1db9b1;
		margin-left: 16px;
		padding: 0;
		&:hover {
			color: #33a7a1;
			border-bottom: 1px solid #33a7a1;
		}
	}
}
/deep/ .el-button {
	margin-left: 16px;
}

/deep/ .mr0 {
	margin-right: 0 !important;
}
/deep/ .w50 {
	width: calc(50% - 8px);
	display: inline-block;
}
/deep/ .w40 {
	width: calc(50% - 14px);
	display: inline-block;
}
/deep/ .m28 {
	margin-right: 28px;
}
/deep/ .el-textarea__inner {
	min-height: 64px !important;
	line-height: 18px !important;
	padding: 5px 12px !important;
}
</style>